/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var VVT = {
		// All pages
		common: {
			init: function () {
				// JavaScript to be fired on all pages

				// Inline svgs
				var svgsToInject = document.querySelectorAll('img.svg'),
					svgInjector = new SVGInjector(svgsToInject);

				// Language selector
				var $langSelector = $('.language-selector');

				$langSelector.on('click', function (e) {
					if (!$(e.target).is('.language-selector__list')) {
						$langSelector.toggleClass('active');
					}
				});

				// Open menu
				$('.btn-hamburger').on('click', function (e) {
					$('.overlay').addClass('active');
					$.scrollLock();
				});

				$('.overlay__close').on('click', function (e) {
					$('.overlay').removeClass('active');
					$.scrollLock();
					e.preventDefault();
				});

				// Match height of posts
				$('.archive-posts .post-excerpt').matchHeight();

				// Match height of facts in case intro
				$('.case-facts-fact .case-facts-fact__wrapper').matchHeight();

				// If no avatars, remove them all
				$('.mdl-testimonials').each(function (i, e) {
					var allMissingAvatars = $.map(
						$(this).find('.testimonial'),
						function (e) {
							return $(e)
								.find('.testimonial__avatar')
								.is('.testimonial__avatar--placeholder');
						}
					);

					if (allMissingAvatars.indexOf(false) === -1) {
						$(this)
							.find('.testimonial__avatar--placeholder')
							.remove();
					}
				});

				// Locations module
				$('.mdl-locations').each(function (i, e) {
					var $el = $(this),
						$filters = $el.find('.mdl-locations__filters');

					// If this module has filters
					if ($filters.length) {
						var $container = $el.find('.mdl-locations__locations'),
							$selects = $filters.find(
								'.mdl-locations__filter-select'
							),
							outputString = '';

						$selects.selectpicker().on('change', function (e) {
							e.preventDefault();
							outputString = '';

							$selects.each(function (i, e) {
								var selectVal = $(this).val()
									? $(this).val()
									: '';
								outputString += selectVal;
							});

							!outputString.length && (outputString = 'all');

							if ($container.mixItUp('isLoaded')) {
								$container.mixItUp('filter', outputString);
							}
						});

						$container.mixItUp({
							animation: {
								enable: false,
							},
							controls: {
								enable: false,
							},
							selectors: {
								target: '.location-excerpt',
							},
							layout: {
								display: 'block',
							},
							callbacks: {
								onMixEnd: function (self) {
									self.$show.matchHeight();
								},
							},
						});
					}
				});

				// Cover slider modules
				$('.mdl-cover-slider').each(function (i, e) {
					var $el = $(this),
						count = $el.find('.swiper-slide').length;

					$el.find('.swiper-container').swiper({
						prevButton: $el.find('.slider-nav__prev')[0],
						nextButton: $el.find('.slider-nav__next')[0],
						preloadImages: false,
						lazyLoading: true,
						lazyLoadingInPrevNext: count > 1 ? true : false,
						grabCursor: count > 1 ? true : false,
						loop: count > 1 ? true : false,
						simulateTouch: count > 1 ? true : false,
					});
				});

				// Slider modules
				$('.mdl-slider').each(function (i, e) {
					var $el = $(this),
						count = $el.find('.swiper-slide').length;

					$el.find('.swiper-container').swiper({
						prevButton: $el.find('.slider-nav__prev')[0],
						nextButton: $el.find('.slider-nav__next')[0],
						preloadImages: false,
						lazyLoading: true,
						lazyLoadingInPrevNext: count > 1 ? true : false,
						grabCursor: count > 1 ? true : false,
						loop: count > 1 ? true : false,
						simulateTouch: count > 1 ? true : false,
					});
				});

				// Two columns modules
				$('.mdl-two-columns').each(function (i, e) {
					var $el = $(this),
						count = $el.find('.swiper-slide').length;

					$el.find('.swiper-container').swiper({
						prevButton: $el.find('.slider-nav__prev')[0],
						nextButton: $el.find('.slider-nav__next')[0],
						preloadImages: false,
						lazyLoading: true,
						lazyLoadingInPrevNext: count > 1 ? true : false,
						grabCursor: count > 1 ? true : false,
						loop: count > 1 ? true : false,
						simulateTouch: count > 1 ? true : false,
						autoHeight: true,
						onLazyImageReady: function (swiper, slide, image) {
							swiper.updateAutoHeight();
						},
					});
				});

				// Case intro
				$('.mdl-case-intro').each(function (i, e) {
					var $el = $(this),
						count = $el.find('.swiper-slide').length;

					$el.find('.swiper-container').swiper({
						prevButton: $el.find('.slider-nav__prev')[0],
						nextButton: $el.find('.slider-nav__next')[0],
						preloadImages: false,
						lazyLoading: true,
						lazyLoadingInPrevNext: count > 1 ? true : false,
						grabCursor: count > 1 ? true : false,
						loop: count > 1 ? true : false,
						simulateTouch: count > 1 ? true : false,
						autoHeight: true,
						onLazyImageReady: function (swiper, slide, image) {
							swiper.updateAutoHeight();
						},
					});
				});

				// Interactive map modules
				$('.mdl-i-map').each(function (i, e) {
					var $imap = $(this), // this module
						$maps = $imap.find('.mdl-i-map-single-map'), // all maps
						$areasWrapper = $imap.find(
							'.mdl-i-map__overview-wrapper'
						),
						$areas = $imap.find('.mdl-i-map-areas'),
						$areasCollection = $areas.find('.mdl-i-map-area'),
						$areasOnMap = $imap.find('.mdl-i-map-overview-area'),
						$overviewContainer = $(
							'.mdl-i-map__overview-map .mdl-i-map-map__container'
						),
						currentMap, // current map id
						mapCollection = {}, // collection of initialized maps
						currentStep = 1, // current step in navigation
						initialized = false,
						$window = $(window);

					// Map object
					function Imap($map, mapData) {
						this.$map = $map;
						this.mapData = mapData;
						this.points = {};
					}

					// Close all points on this map
					Imap.prototype.closeAllPoints = function (id) {
						if (typeof id === 'undefined') {
							$.each(this.points, function (i, e) {
								e.el.removeClass('active');
							});
						} else {
							$.each(this.points, function (i, e) {
								if (e.id !== id) {
									e.el.removeClass('active');
								}
							});
						}
					};

					// Open a point (not used because everything is accessible in closure)
					Imap.prototype.openPoint = function (data) {
						if (data.hasOwnProperty('point')) {
							// shortcut
							data.point.addClass('active');
						} else {
							$.each(this.points, function (i, e) {
								if (e.id == data.id) {
									e.el.addClass('active');
								}
							});
						}
					};

					// Close a point (not used because everything is accessible in closure)
					Imap.prototype.closePoint = function (data) {
						if (data.hasOwnProperty('point')) {
							// shortcut
							data.point.removeClass('active');
						} else {
							$.each(this.points, function (i, e) {
								if (e.id == data.id) {
									e.el.removeClass('active');
								}
							});
						}
					};

					Imap.prototype.hideMap = function () {
						this.$map.hide();
					};

					Imap.prototype.showMap = function () {
						this.$map.show();
					};

					Imap.prototype.centerMap = function () {
						var $cont = this.$map.find('.mdl-i-map-map__container'),
							$mapWindow = this.$map.find('.mdl-i-map-map'),
							width = $cont.width() - $mapWindow.width(),
							height = $cont.height() - $mapWindow.height();

						$cont
							.panzoom('resetDimensions')
							.panzoom('pan', -width / 2, -height / 2);
					};

					Imap.prototype.init = function () {
						// First show this map
						this.showMap();

						var that = this,
							$cont = this.$map.find('.mdl-i-map-map__container'),
							$mapWindow = this.$map.find('.mdl-i-map-map'),
							$infoPane = this.$map.find('.mdl-i-map-info-pane'),
							$infoPaneContent = this.$map.find(
								'.mdl-i-map-info-pane__content'
							),
							$locationsListItems = $(
								'<ul class="mdl-i-map-info-pane__list">'
							),
							$locationContent = $infoPane.find(
								'.mdl-i-map-info-pane__content--location'
							);

						var mapImageWidth = $cont.attr('data-width'),
							mapImageHeight = $cont.attr('data-height'),
							mapImageSrc = $cont.attr('data-src'),
							$mapImage = $(
								'<img class="mdl-i-map-map__content__img" alt="" src="' +
									mapImageSrc +
									'" width="' +
									mapImageWidth +
									'" height="' +
									mapImageHeight +
									'" />'
							);

						$cont.append($mapImage);
						$cont.imagesLoaded().always(function () {
							$mapWindow
								.find('.mdl-i-map__loading')
								.addClass('loaded');
						});

						// Close map button
						this.$map.on(
							'click',
							'.mdl-i-map__close-map',
							function (e) {
								currentStep = 1;
								that.closeAllPoints();
								$locationsListItems
									.find('li')
									.removeClass('active');
								that.$map
									.find('.mdl-i-map-info-pane')
									.removeClass('active');
								that.hideMap();
								$areasWrapper.addClass('active');

								e.preventDefault();
							}
						);

						// Panzoom plugin
						$cont
							.panzoom({
								contain: 'invert',
								disableZoom: true,
								cursor: 'grab',
							})
							.on('panzoomstart', function () {
								$(this).addClass('moving');
							})
							.on('panzoomend', function () {
								$(this).removeClass('moving');
							});

						$window.on('resize', function () {
							$cont.panzoom('resetDimensions');
						});

						// Loop through points and create markers. Add marker and additional data to points cache.
						$.each(this.mapData, function (i, e) {
							var pointHtml =
									'<div class="mdl-i-map-point">' +
									'<div class="mdl-i-map-point__marker"><div class="pulse"></div></div>' +
									'<div class="mdl-i-map-point__info">' +
									'<h3 class="mdl-i-map-point__title">' +
									e.name +
									'</h3>' +
									'<p class="mdl-i-map-point__text">' +
									e.description +
									'</p>' +
									'</div>' +
									'</div>',
								$point = $(pointHtml),
								$pointInfoWindow = $point.find(
									'.mdl-i-map-point__info'
								),
								$pointList = $point.find(
									'.mdl-i-map-point__list'
								),
								pointId = e.id;

							// Create html for the list of locations
							var $listItem = $('<li>' + e.name + '</li>');
							$listItem.appendTo($locationsListItems);

							// Create the permalink
							if (e.permalink) {
								$(
									'<a class="mdl-i-map-point__link" href="' +
										encodeURI(e.permalink) +
										'">naar de detailpagina &raquo;</a>'
								).appendTo($pointInfoWindow);
							}

							$listItem.on('click', function () {
								that.closeAllPoints();
								$listItem.siblings().removeClass('active');

								if (!$listItem.hasClass('active')) {
									$point.addClass('active');
									var position = $point.position();
									$cont.panzoom(
										'pan',
										-(position.left - 80),
										-(position.top - 80),
										{ animate: true }
									);
								}

								$listItem.toggleClass('active');
								currentStep = 2;
							});

							// Append point to container and add click handler
							$point
								.css({
									left: e.x - 16 + 'px',
									top: e.y - 16 + 'px',
								})
								.on(
									'click',
									'.mdl-i-map-point__marker',
									function (e) {
										// Close point and remove active state from all list items
										that.closeAllPoints(pointId);
										$listItem
											.siblings()
											.removeClass('active');

										if ($point.hasClass('active')) {
											$point.removeClass('active');
											$listItem.removeClass('active');
											currentStep = 2;
										} else {
											$point.addClass('active');
											$listItem.addClass('active');
											currentStep = 2;
										}
									}
								)
								.appendTo($cont);

							// Add this point to the cache
							that.points[pointId] = $.extend({}, e, {
								el: $point,
							});
						});

						// Create the list of locations
						$locationsListItems.appendTo($infoPaneContent);

						this.$map
							.find('.mdl-i-map-info-pane__slider')
							.perfectScrollbar();
					};

					function showMap(id) {
						mapCollection[id].showMap();
					}

					function createMap(id) {
						currentMap = id;
						mapCollection[id] = new Imap(
							$maps.eq(id),
							window[
								$maps.eq(id).attr('data-map-data') + 'MapData'
							]
						);
						mapCollection[id].init();
					}

					function openMap(id) {
						if (id in mapCollection) {
							showMap(id);
						} else {
							createMap(id);
						}

						mapCollection[id].centerMap();

						currentStep = 2;
					}

					function initMapModule() {
						// Panzoom plugin for homescreen
						$overviewContainer
							.panzoom({
								contain: 'invert',
								disableZoom: true,
								cursor: 'grab',
							})
							.panzoom('pan', -177, 0)
							.on('panzoomstart', function () {
								$(this).addClass('moving');
							})
							.on('panzoomend', function () {
								$(this).removeClass('moving');
							});

						$window.on('resize', function () {
							$overviewContainer.panzoom('resetDimensions');
						});

						$areas
							.on('mouseenter', '.mdl-i-map-area', function (e) {
								var $el = $(this),
									$areaOnMap = $areasOnMap.eq($el.index()),
									position = $areaOnMap.position();

								$areasOnMap.removeClass('active');
								$areaOnMap.addClass('active');

								$overviewContainer.panzoom(
									'pan',
									-(position.left - 80),
									-(position.top - 80),
									{ animate: true }
								);
							})
							.on('mouseleave', '.mdl-i-map-area', function (e) {
								$areasOnMap.removeClass('active');
							});

						$areasOnMap
							.on('mouseenter', function (e) {
								$areasCollection.removeClass('active');
								$areasCollection
									.eq($(this).index())
									.addClass('active');
							})
							.on('mouseleave', function (e) {
								$areasCollection.removeClass('active');
							});

						initialized = true;
					}

					// Only init maps if not on mobile
					if ($window.width() > 767) {
						initMapModule();
					}

					$window.on('resize', function () {
						if ($window.width() > 767 && !initialized) {
							initMapModule();
						}
					});

					// Open the map of an area from the menu
					$areas.on('click', '.mdl-i-map-area', function (e) {
						if ($window.width() > 767) {
							var mapIndex = $(this).index();
							openMap(mapIndex);
							$areasWrapper.removeClass('active');
						}

						if ($window.width() < 768) {
							$(this).siblings().removeClass('active');
							$(this).toggleClass('active');
						}
					});

					// Open the map of an area from the map
					$areasOnMap.on('click', function (e) {
						if ($window.width() > 767) {
							var mapIndex = $(this).index();
							openMap(mapIndex);
							$areasWrapper.removeClass('active');
						}
					});
				});

				// Timeline modules
				$('.mdl-timeline').each(function (i, e) {
					var $el = $(this),
						count = $el.find('.swiper-slide').length;

					var timeLineNav = $el
						.find('.timeline-nav .swiper-container')
						.swiper({
							centeredSlides: true,
							slidesPerView: 'auto',
							slideToClickedSlide: true,
							speed: 500,
						});

					var timeLine = $el
						.find('.mdl-timeline__content .swiper-container')
						.swiper({
							prevButton: $el
								.parent()
								.find('.timeline-nav__prev')[0],
							nextButton: $el
								.parent()
								.find('.timeline-nav__next')[0],
							preloadImages: false,
							lazyLoading: true,
							autoHeight: false,
							lazyLoadingInPrevNext: count > 1 ? true : false,
							grabCursor: count > 1 ? true : false,
							loop: false,
							speed: 500,
						});

					timeLine.params.control = timeLineNav;
					timeLineNav.params.control = timeLine;

					$('.mdl-timeline__slide').matchHeight();
				});

				// Video modules
				var $videos = $('.mdl-video');

				if ($videos.length) {
					var tag = document.createElement('script');
					tag.src = 'https://www.youtube.com/player_api';
					var firstScriptTag =
						document.getElementsByTagName('script')[0];
					firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
				}

				window.onYouTubeIframeAPIReady = function () {
					VVT._handleVideoModules($videos);
				};
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			},
		},
		_handleVideoModules: function ($els) {
			$els.each(function (i, e) {
				var $el = $(this),
					player;

				$el.on('click', '.mdl-video__play-btn', function (e) {
					$el.addClass('active');

					player = new YT.Player($el.find('.ytplayer')[0], {
						height: '390',
						width: '640',
						videoId: $el.attr('data-yt'),
						playerVars: {
							autoplay: 1,
							showinfo: 0,
							rel: 0,
						},
					});
				});
			});
		},
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = VVT;
			funcname = funcname === undefined ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(
				document.body.className.replace(/-/g, '_').split(/\s+/),
				function (i, classnm) {
					UTIL.fire(classnm);
					UTIL.fire(classnm, 'finalize');
				}
			);

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		},
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
